<template>
  <div 
    ref="chatContainerRef" 
    class="flex-grow overflow-y-auto p-4 space-y-4"
  >
    <div 
      v-for="(msg, index) in messages" 
      :key="index" 
      class="message-wrapper"
    >
      <div 
        v-if="msg.type === 'user' || msg.type === 'ai'"
        :class="[
          msg.type === 'user' 
            ? 'flex justify-end' 
            : 'flex justify-start'
        ]"
      >
        <div 
          :class="[
            msg.type === 'user' 
              ? 'message-user p-4 rounded-xl border border-white/10' 
              : 'message-ai p-4 rounded-xl', 
            'max-w-[80%]'
          ]"
          v-html="formatMessage(msg)"
        ></div>
      </div>

      <!-- Reference Search Results Content -->
      <div 
        v-if="msg.type === 'reference' && msg.references.length"
        class="search-references text-sm my-2"
      >
        <strong>Reference Search:</strong>
        <div class="space-y-1">
          <a 
            v-for="(ref, refIndex) in msg.references" 
            :key="refIndex"
            :href="ref" 
            target="_blank" 
            class="block truncate text-blue-500 underline"
          >
            {{ ref }}
          </a>
        </div>
      </div>

      <!-- Web Search Results Content -->
      <div 
        v-if="msg.type === 'web-search' && msg.webResults.length"
        class="search-references text-sm my-2"
      >
        <strong>Web Search Results:</strong>
        <div class="space-y-2">
          <div 
            v-for="(result, resultIndex) in msg.webResults" 
            :key="resultIndex"
            class="bg-[#444654] p-2 rounded"
          >
            <a 
              :href="result.url" 
              target="_blank" 
              class="text-blue-500 font-semibold underline block"
            >
              {{ result.title }}
            </a>
            <p class="text-xs text-gray-300 mt-1">
              {{ result.snippets.join(' ') }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="flex justify-start">
      <div class="max-w-[80%] px-4 py-2 rounded-2xl bg-[#444654] text-white shadow-sm break-words">
        <div class="loading-dots">
          Processing
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'

const messages = ref([])
const loading = ref(false)
const chatContainerRef = ref(null)

function cleanText(text) {
  return text.replace(/\[\^?\d+\^?\]/g, '').trim()
}

function formatMessage(msg) {
  const cleanedContent = cleanText(msg.content)
  return formatText(createClickableLinks(escapeHtml(cleanedContent)))
}


function createClickableLinks(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g
  return text.replace(urlRegex, (url) => 
    `<a href="${url}" target="_blank" class="text-blue-500 underline">${url}</a>`
  )
}

function formatText(text) {
    text = text.replace(/&#039;/g, "'")
               .replace(/&quot;/g, '"')
               .replace(/&amp;/g, '&')
               .replace(/&lt;/g, '<')
               .replace(/&gt;/g, '>');

    text = text.replace(/```(\w*)\n([\s\S]*?)```/g, function(match, lang, code) {
        // Code filter
        const cleanCode = code.trim().replace(/^\s+|\s+$/g, '');
        return `<pre><code class="language-${lang || 'plaintext'}">${escapeHtml(cleanCode)}</code></pre>`;
    });

    text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

    text = text.replace(/(?:^|\n)(\d+)\.\s+(.*?)(?=\n|$)/g, '<li>$2</li>');
    if (/<li>/.test(text)) {
        text = text.replace(/(<li>.*?<\/li>)/g, '<ol class="list-decimal pl-4">$1</ol>');
    }

    text = text.replace(/(?:^|\n)-\s+(.*?)(?=\n|$)/g, '<li>$1</li>');
    if (/<li>/.test(text)) {
        text = text.replace(/(<li>.*?<\/li>)/g, '<ul class="list-disc pl-4">$1</ul>');
    }

    return text;
}

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}
function addMessage(content, type, searchResults = [], webSearchResults = []) {
  // Link URL //
  const cleanedSearchResults = searchResults.map(result => 
    typeof result === 'object' ? result.url : result
  ).filter(url => url && url.startsWith('http'))

  messages.value.push({ content, type })
  
  // Search result (jika ada)
  if (cleanedSearchResults.length > 0) {
    messages.value.push({
      type: 'reference',
      references: cleanedSearchResults
    })
  }
  
  // Web result (jika ada)
  if (webSearchResults.length > 0) {
    messages.value.push({
      type: 'web-search',
      webResults: webSearchResults
    })
  }
  
  nextTick(() => {
    if (chatContainerRef.value) {
      chatContainerRef.value.scrollTop = chatContainerRef.value.scrollHeight
    }
  })
}

function showLoading() {
  loading.value = true
}

function removeLoading() {
  loading.value = false
}

defineExpose({
  addMessage,
  showLoading,
  removeLoading
})
</script>

<style scoped>
</style>