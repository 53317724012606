import axios from 'axios'

const API_BASE_URL = 'https://api-bing.snd.web.id/api/bing.php'

export async function fetchBingAIResponse(message) {
  try {
    const response = await axios.get(`${API_BASE_URL}?q=${encodeURIComponent(message)}`)
    //console.log('🚀 RESPONSE API:', response)
    return response.data
  } catch (error) {
    console.error('🚀 API Error:', error)
    throw error
  }
}
