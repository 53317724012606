<template>
  <div class="p-4 border-t border-white/10">
    <div class="relative max-w-3xl mx-auto">
      <input 
        type="text" 
        v-model="message"
        @keyup.enter="sendMessageHandler"
        placeholder="Type your question..." 
        class="w-full flex-grow p-3 rounded-lg border border-white/10 text-white bg-[#444654] focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
      <button 
        @click="sendMessageHandler"
        class="absolute right-2 top-1/2 -translate-y-1/2 bg-[#19c37d] p-2 rounded-lg hover:bg-opacity-90 transition flex items-center justify-center"
      >
        <span class="material-icons text-white">send</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const message = ref('')
const emit = defineEmits(['send-message'])

function sendMessageHandler() {
  if (message.value.trim()) {
    emit('send-message', message.value)
    message.value = ''
  }
}
</script>