<template>
  <div class="bg-bg-primary text-white flex flex-col h-screen">
    <div class="flex flex-col flex-grow w-full max-w-4xl mx-auto">
      <ChatHeader />
      <ChatContainer ref="chatContainer" />
      <ChatInput @send-message="sendMessage" />
      <ChatFooter />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ChatHeader from './components/ChatHeader.vue'
import ChatContainer from './components/ChatContainer.vue'
import ChatInput from './components/ChatInput.vue'
import ChatFooter from './components/ChatFooter.vue'
import { fetchBingAIResponse } from './services/apiService'

const chatContainer = ref(null)

async function sendMessage(message) {
  try {
    chatContainer.value.addMessage(message, 'user')
    chatContainer.value.showLoading()
    const response = await fetchBingAIResponse(message)
    chatContainer.value.removeLoading()

    if (response.result && response.result.ai_response) {
      chatContainer.value.addMessage(
        response.result.ai_response, 
        'ai', 
        response.result.search_results || [], 
        response.result.web_search_results || []
      )
    }
  } catch (error) {
    console.error('Error:', error)
    chatContainer.value.removeLoading()
    chatContainer.value.addMessage('Error in fetching response. Please try again later.', 'ai')
  }
}
</script>